<template>
    <div class="flex-between flex-v-center tag-title">
        <div class="flex-v-center">
            <slot name="icon">
                <div
                    style="width: 4px; height: 16px; background: #e54545"
                ></div>
            </slot>
            <span
                class="bold h5 ml5 title-text"
                :class="{ 'light-text': isLight }"
                >{{ title }}</span
            >
        </div>
        <div>
            <slot name="righter"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            default: '默认文字',
        },
        isLight: {
            default: false,
        },
    },
};
</script>
<style lang="less" scoped>
.light-text {
    color: #999;
}
</style>
