<template>
    <div>
        <div class="user-banner mb10">
            <img
                class="wp100 vertical-mid"
                src="../../../assets/img/banner/main.webp"
                alt=""
            />
        </div>
        <div class="flex">
            <div style="flex-grow: 1">
                <MainContent />
            </div>
        </div>
    </div>
</template>

<script>
import MainContent from './Index/MainContent.vue';

export default {
    name: 'Index',
    components: { MainContent },
};
</script>
