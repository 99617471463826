<template>
    <div>
        <div class="bg-white pd24">
            <TagTitle title="快捷入口" />
            <Row :gutter="16" class="mt10">
                <Col :span="12">
                    <div
                        class="flex pd10 pointer card-fd-qd"
                        @click="handleBuyerBid"
                    >
                        <div class="pdl20">
                            <img
                                height="160"
                                src="@/assets/img/main/fd.png"
                                alt=""
                            />
                        </div>
                        <div style="padding: 20px">
                            <h1>去发单</h1>
                            <div class="h3">
                                低价、现货、快速报上门 <br />供您精选成交
                            </div>
                        </div>
                    </div>
                </Col>
                <Col :span="12">
                    <div
                        class="flex pd10 pointer card-fd-qd"
                        @click="handleSellerBid"
                    >
                        <div class="pdl20">
                            <img
                                height="160"
                                src="@/assets/img/main/qd.png"
                                alt=""
                            />
                        </div>
                        <div style="padding: 20px">
                            <h1>去抢单</h1>
                            <div class="h3">
                                全国成千上万采购需求在眼前
                                <br />供您大卖优势产品
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>

        <div class="bg-white pd24">
            <TagTitle title="最新成交" class="mb10" />
            <vue-seamless-scroll
                :class-option="classOption"
                :data="data"
                class="scroll"
            >
                <Row v-for="item of data" :key="item.uniqId" class="item mb20">
                    <Col :span="4">
                        {{ item.dealTime }}
                    </Col>
                    <Col :span="4">
                        {{ item.provinceStr }}
                    </Col>
                    <Col :span="4">
                        {{ item.cityStr }}
                    </Col>
                    <Col :span="4">
                        {{ item.brandName }}
                    </Col>
                    <Col :span="4">
                        {{ item.productNo }}
                    </Col>
                    <Col :span="4">
                        {{ item.num }}
                    </Col>
                </Row>
            </vue-seamless-scroll>
        </div>

        <div class="mt10">
            <img
                class="wp100"
                src="../../../../assets/img/main/bot.webp"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import TagTitle from '@/components/TagTitle.vue';
import userInfoMixin from '@/mixins/userInfo.mixin';
import vueSeamlessScroll from 'vue-seamless-scroll';

export default {
    name: 'MainContent',
    components: { TagTitle, vueSeamlessScroll },
    mixins: [userInfoMixin],
    data() {
        return {
            classOption: {
                step: 0.5,
            },
            data: [],
            columns: [
                {
                    title: '成交时间',
                    key: 'dealTime',
                },
                {
                    title: '省份',
                    key: 'provinceStr',
                },
                {
                    title: '城市',
                    key: 'cityStr',
                },
                {
                    title: '品牌',
                    key: 'brandName',
                },
                {
                    title: '货号',
                    key: 'productNo',
                },
                {
                    title: '数量单位',
                    key: 'num',
                },
            ],
        };
    },
    computed: {
        hasLogin() {
            return this.$userInfo.id;
        },
    },
    created() {
        this.getData();
    },
    methods: {
        handleBuyerBid() {
            if (!this.hasLogin) {
                this.$router.push('/login');
                return;
            }
            this.$router.push('/home/buyer/bill');
        },
        handleSellerBid() {
            if (!this.hasLogin) {
                this.$router.push('/login');
                return;
            }
            this.$router.push('/home/seller/bidIndex');
        },
        async getData() {
            let res = await $service.index.getInfo15();
            let newData = [];
            for (let it of _.get(res, 'data') || []) {
                newData.push({
                    dealTime: $util.format$Date(it.dealTime),
                    provinceStr: it.provinceStr,
                    cityStr: it.cityStr,
                    brandName: it.brandName,
                    productNo: it.productNo,
                    num: it.num + it.unit,
                    uniqId: $util.getUniqId(),
                });
            }
            this.data = newData;
        },
    },
};
</script>

<style lang="less" scoped>
.card-fd-qd {
    background: #ffeef1;
    padding: 20px;
    border-radius: 8px;
}

.scroll {
    height: 420px;
    overflow: hidden;
}
</style>
